// @flow strict
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';

import { useSiteMetadata } from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
        <Link to="https://www.credly.com/badges/b3f49b16-9ace-47a5-9471-eb3740835242/public_url">
          <img
            src={withPrefix("/aws-certified-cloud-practitioner.png")}
            className={styles['author__photo']}
            width="72"
            height="72"
            alt="AWS Certified Cloud Practitioner"
          />
        </Link>
        <Link to="https://www.credly.com/badges/30a0935d-b054-477d-aa27-43278702da08/public_url">
          <img
            src={withPrefix("/aws-certified-solutions-architect-associate.png")}
            className={styles['author__photo']}
            width="72"
            height="72"
            alt="AWS Certified Solutions Architect - Associate"
          />
        </Link>

        <div data-iframe-width="150" data-iframe-height="270"
          data-share-badge-id="b3f49b16-9ace-47a5-9471-eb3740835242"
          data-share-badge-host="https://www.credly.com">
        </div>
        <script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script>
      </div>
    </div>
  );
};

export default Sidebar;
